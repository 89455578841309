<template>
  <div>
    <NavBar />
    <meeting-minutes-form />
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import MeetingMinutesForm from "../components/MeetingMinutesForm";

export default {
  name: "Meeting",

  components: {
    NavBar,
    MeetingMinutesForm,
  },
};
</script>
