<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-container>
      <div
        v-show="isEditing == 0 && loadExistMeetings == 0"
        class="pb-10 grey--text text--darken-1 display-1"
      >
        <v-row>
          <v-col cols="12" md="6">
            <span>{{ $t("meeting_minutes_form.info_create") }}</span>
          </v-col>

          <v-col cols="12" md="6" class="text-center">
            <v-file-input
              accept=".json"
              :label="$t('meeting_minutes_form.insert_json')"
              outlined
              @change="loadOfflineData()"
              v-model="chosenFile"
            >
            </v-file-input>
            <v-spacer></v-spacer>
          </v-col>
        </v-row>
      </div>

      <v-alert v-show="errorAxios" class="mb-6" type="error">
        {{ $t("meeting_table.error_connection") }}
      </v-alert>

      <v-dialog v-model="loading" fullscreen>
        <v-container
          fluid
          fill-height
          style="background-color: rgba(255, 255, 255, 0.4)"
        >
          <v-layout justify-center align-center>
            <v-progress-circular
              indeterminate
              :size="60"
              :width="7"
              color="primary"
            >
            </v-progress-circular>
          </v-layout>
        </v-container>
      </v-dialog>

      <div
        v-show="isEditing == 0 && loadExistMeetings == 1"
        class="pb-10 grey--text text--darken-1 display-1"
      >
        {{ $t("meeting_minutes_form.info_edit") }}
        {{ $t("meeting_minutes_form.number") }} {{ idm }}
      </div>
      <div
        v-show="isEditing == 1"
        class="pb-10 grey--text text--darken-1 display-1"
      >
        {{ $t("meeting_minutes_form.info_show") }}
        {{ $t("meeting_minutes_form.number") }} {{ idm }}
      </div>
      <v-divider></v-divider>
      <div class="pt-6 pb-4">
        <p class="headline text--secondary">
          {{ $t("meeting_minutes_form.info") }}
        </p>
      </div>

      <v-row class="pb-6">
        <v-col cols="12" md="12">
          <v-combobox
            clearable
            v-model="selectProject"
            prepend-icon="mdi-assistant"
            :items="itemProject"
            :label="$t('meeting_minutes_form.project')"
            :rules="[requiredFieldComboboxProject]"
            required
            :disabled="isEditing == 1"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="5">
          <v-text-field
            prepend-icon="mdi-shape-outline"
            v-model="textTopic"
            :rules="requiredField"
            :label="$t('meeting_minutes_form.topic')"
            required
            :disabled="isEditing == 1"
          ></v-text-field>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="5">
          <v-combobox
            clearable
            v-model="selectOvm"
            prepend-icon="mdi-home"
            :items="itemOvm"
            :label="$t('meeting_minutes_form.ovm')"
            :rules="[requiredFieldComboboxOvm]"
            required
            :disabled="isEditing == 1"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="3">
          <v-menu
            ref="menuDate"
            v-model="menuDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :disabled="isEditing == 1"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                :label="$t('meeting_minutes_form.date_title')"
                prepend-icon="mdi-calendar"
                clearable
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="requiredField"
                @click:clear="date = null"
                required
                :disabled="isEditing == 1"
              ></v-text-field>
            </template>
            <v-date-picker
              :first-day-of-week="1"
              v-model="date"
              locale="sk-SK"
              scrollable
              @change="menuDate = false"
              :disabled="isEditing == 1"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="3">
          <v-menu
            ref="menuTimeBegin"
            v-model="menuTimeBegin"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="timeBegin"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="timeBegin"
                :label="$t('meeting_minutes_form.time_begin')"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                clearable
                v-bind="attrs"
                v-on="on"
                :rules="requiredField"
                required
                :disabled="isEditing == 1"
              ></v-text-field>
            </template>
            <v-time-picker
              format="24hr"
              locale="sk-SK"
              v-if="menuTimeBegin"
              v-model="timeBegin"
              full-width
              @click:minute="$refs.menuTimeBegin.save(timeBegin)"
            >
            </v-time-picker>
          </v-menu>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" md="3">
          <v-menu
            ref="menuTimeEnd"
            v-model="menuTimeEnd"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="timeEnd"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="timeEnd"
                :label="$t('meeting_minutes_form.time_end')"
                prepend-icon="mdi-clock-time-four-outline"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                :rules="requiredField"
                required
                :disabled="isEditing == 1"
              ></v-text-field>
            </template>
            <v-time-picker
              format="24hr"
              locale="sk-SK"
              v-if="menuTimeEnd"
              v-model="timeEnd"
              full-width
              @click:minute="$refs.menuTimeEnd.save(timeEnd)"
            >
            </v-time-picker>
          </v-menu>
        </v-col>

        <v-spacer></v-spacer>

        <v-col cols="12" md="3">
          <v-combobox
            clearable
            v-model="selectVersion"
            prepend-icon="mdi-lead-pencil"
            :items="itemVersion"
            :label="$t('meeting_minutes_form.version')"
            :rules="[requiredFieldComboboxVersion]"
            required
            :disabled="isEditing == 1"
          ></v-combobox>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" lg="6">
          <v-row>
            <v-col cols="10">
              <v-combobox
                clearable
                class="mt-6"
                v-model="selectProcessedPerson"
                @input.native="actualProcessedPerson = $event.srcElement.value"
                prepend-icon="mdi-account"
                :items="itemPersons"
                :label="$t('meeting_minutes_form.who_processed')"
                :rules="[requiredFieldUserProcessed(actualProcessedPerson)]"
                @change="actualProcessedPerson = selectProcessedPerson"
                required
                :disabled="isEditing == 1"
              ></v-combobox>
            </v-col>
            <v-col cols="1" class="pt-10 text-right">
              <v-btn
                v-if="
                  !!actualProcessedPerson &&
                  !this.isExistPerson(actualProcessedPerson)
                "
                :disabled="isEditing == 1"
                fab
                color="primary"
                @click="onChangeProcessedPerson()"
              >
                <v-icon dark> mdi-folder-plus-outline </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" lg="6">
          <v-row>
            <v-col cols="10">
              <v-combobox
                clearable
                class="mt-6"
                v-model="selectApprovedPerson"
                @input.native="actualApprovedPerson = $event.srcElement.value"
                prepend-icon="mdi-account"
                :items="itemPersons"
                :label="$t('meeting_minutes_form.who_approved')"
                :rules="[requiredFieldUserApproved(actualApprovedPerson)]"
                @change="actualApprovedPerson = selectApprovedPerson"
                required
                :disabled="isEditing == 1"
              ></v-combobox>
            </v-col>
            <v-col cols="1" class="pt-10 text-right">
              <v-btn
                v-if="
                  !!actualApprovedPerson &&
                  !this.isExistPerson(actualApprovedPerson)
                "
                :disabled="isEditing == 1"
                fab
                color="primary"
                @click="onChangeApprovedPerson()"
              >
                <v-icon dark> mdi-folder-plus-outline </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="pb-6">
        <v-col cols="12" md="12">
          <v-text-field
            prepend-icon="mdi-google-maps"
            v-model="textPlace"
            :rules="requiredField"
            :label="$t('meeting_minutes_form.place')"
            required
            :disabled="isEditing == 1"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <div class="pt-6 pb-4">
        <p class="headline text--secondary">
          {{ $t("meeting_minutes_form.user_on_meetings") }}
        </p>
      </div>

      <v-row justify="center">
        <v-dialog v-model="dialogNewPerson" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{
                $t("meeting_minutes_form.create_and_insert")
              }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      prepend-icon="mdi-account"
                      v-model="textName"
                      :label="$t('meeting_minutes_form.name')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-text-field
                      prepend-icon="mdi-at"
                      v-model="textEmail"
                      :label="$t('meeting_minutes_form.email')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12">
                    <v-text-field
                      prepend-icon="mdi-cellphone"
                      v-model="textPhone"
                      :label="$t('meeting_minutes_form.phone')"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <v-text-field
                      prepend-icon="mdi-cog-outline"
                      v-model="textPosition"
                      :label="$t('meeting_minutes_form.position')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-combobox
                      clearable
                      v-model="selectOvmAddUser"
                      prepend-icon="mdi-home"
                      :items="itemOvm"
                      :label="$t('meeting_minutes_form.organization')"
                    ></v-combobox>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeAddPerson()">
                {{ $t("meeting_minutes_form.close") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="addPerson()">
                {{ $t("meeting_minutes_form.save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="dialogDeletRow" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{
                $t("meeting_minutes_form.title_delete_row")
              }}</span>
            </v-card-title>
            <v-card-text> </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDeletRow = false">
                {{ $t("meeting_minutes_form.delete_no") }}
              </v-btn>
              <v-btn color="blue darken-1" text @click="deleteTableRow()">
                {{ $t("meeting_minutes_form.delete_yes") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="dialogError" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{
                $t("meeting_minutes_form.error_validation")
              }}</span>
            </v-card-title>
            <v-card-text> </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogError = false">
                {{ $t("meeting_minutes_form.ok") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="dialogSave" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{
                $t("meeting_minutes_form.save_successful")
              }}</span>
            </v-card-title>
            <v-card-text> </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogSave = false">
                {{ $t("meeting_minutes_form.ok") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center">
        <v-dialog v-model="dialogSaveWord" persistent max-width="650px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{
                $t("task_form.save_word_successful")
              }}</span>
            </v-card-title>
            <v-card-text> </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogSaveWord = false">
                {{ $t("task_form.ok") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-col cols="2" v-show="isEditing == 0" class="pt-7">
        <!--          Add Svg Icon-->
        <svg
          v-show="participantsList.length == 0"
          @click="addFirstFieldParticipantList()"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          class="ml-2 cursor-pointer"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            fill="#6cc24a"
            d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
          />
        </svg>
      </v-col>

      <v-row
        class=""
        v-for="(input, index) in participantsList"
        :key="`participantsLists-${index}`"
      >
        <v-col cols="12" lg="12">
          <v-row>
            <v-col cols="9">
              <v-combobox
                clearable
                class="mt-6"
                v-model="input.select"
                @input.native="input.actual = $event.srcElement.value"
                prepend-icon="mdi-account"
                :items="itemPersons"
                :label="$t('meeting_minutes_form.name')"
                :rules="[requiredFieldUserParticipant(index)]"
                @change="input.actual = input.select"
                required
                :disabled="isEditing == 1"
              ></v-combobox>
            </v-col>
            <v-col cols="1" class="pt-10 text-right">
              <v-btn
                v-if="input.addUser"
                :disabled="isEditing == 1"
                fab
                color="primary"
                @click="onChangeParticipantPerson(index)"
              >
                <v-icon dark> mdi-folder-plus-outline </v-icon>
              </v-btn>
            </v-col>

            <v-col cols="2" class="pt-12" v-show="isEditing == 0">
              <!--          Add Svg Icon-->
              <svg
                @click="addFieldParticipantList()"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="ml-2 cursor-pointer"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="#6cc24a"
                  d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                />
              </svg>

              <!--          Remove Svg Icon-->
              <svg
                @click="removeFieldParticipantList(index)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="ml-2 cursor-pointer"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  fill="#ed9b33"
                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                />
              </svg>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <div class="pt-8">
        <v-divider></v-divider>
      </div>

      <div class="pt-8 pb-4">
        <p class="headline text--secondary">
          {{ $t("meeting_minutes_form.agenda") }}
        </p>
      </div>
      <v-textarea
        auto-grow
        outlined
        label="Zadajte text."
        v-model="editorAgenda"
        :disabled="isEditing == 1"
      ></v-textarea>

      <div class="pt-8">
        <v-divider></v-divider>
      </div>
      <div class="pt-8 pb-4">
        <p class="headline text--secondary">
          {{ $t("meeting_minutes_form.to_do_list") }}
        </p>
      </div>

      <v-col v-show="isEditing == 0" cols="2" class="pt-7">
        <!--          Add Svg Icon-->
        <svg
          v-show="toDoLists.length == 0"
          @click="addFirstField()"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
          class="ml-2 cursor-pointer"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            fill="#6cc24a"
            d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
          />
        </svg>
      </v-col>

      <v-row
        class="mb-32"
        v-for="(input, index) in toDoLists"
        :key="`toDoLists-${index}`"
      >
        <v-col cols="12" lg="1" class="mt-4 text-center">
          <span class="text-h6">{{ index + 1 }} )</span>
        </v-col>
        <v-col cols="12" lg="5">
          <v-combobox
            clearable
            v-model="input.selectTaskType"
            prepend-icon="mdi-android-messages"
            :items="itemTaskType"
            :label="$t('meeting_minutes_form.task_type')"
            :rules="[requiredFieldComboboxTaskType]"
            :change="onChangeTaskType(index)"
            required
            :disabled="isEditing == 1"
          ></v-combobox>
        </v-col>

        <v-col cols="12" lg="6" v-if="input.showStatus">
          <v-combobox
            clearable
            v-model="input.selectStatus"
            prepend-icon="mdi-list-status"
            :items="itemStatus"
            :label="$t('meeting_minutes_form.status')"
            :rules="[requiredFieldComboboxStatus]"
            required
            :disabled="isEditing == 1"
          ></v-combobox>
        </v-col>

        <v-col cols="12" lg="6" v-if="input.showDate">
          <v-menu
            ref="menu"
            v-model="input.menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
            :disabled="isEditing == 1"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="input.deadline"
                :label="$t('meeting_minutes_form.deadtime')"
                clearable
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                @click:clear="input.date = null"
                :rules="requiredField"
                required
                :disabled="isEditing == 1"
              ></v-text-field>
            </template>
            <v-date-picker
              :first-day-of-week="1"
              v-model="input.deadline"
              locale="sk-SK"
              scrollable
              @change="input.menu = false"
            >
            </v-date-picker>
          </v-menu>
        </v-col>

        <v-col cols="11" lg="5" v-if="input.showResponsible">
          <v-combobox
            v-model="input.responsible"
            @input.native="input.actual = $event.srcElement.value"
            prepend-icon="mdi-account"
            :items="itemPersons"
            :label="$t('meeting_minutes_form.responsible')"
            :rules="[requiredFieldUserTask(index)]"
            @change="input.actual = input.responsible"
            required
            :disabled="isEditing == 1"
          ></v-combobox>
        </v-col>
        <v-col cols="1" class="pt-3 text-right">
          <v-btn
            v-if="input.addUser"
            :disabled="isEditing == 1"
            fab
            color="primary"
            @click="onChangeTaskPerson(index)"
          >
            <v-icon dark> mdi-folder-plus-outline </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" lg="12">
          <v-textarea
            auto-grow
            prepend-icon="mdi-chart-box-outline"
            outlined
            required
            :rules="requiredField"
            :label="$t('meeting_minutes_form.task_name')"
            v-model="input.name"
            :disabled="isEditing == 1"
          ></v-textarea>
        </v-col>

        <v-col cols="2" class="pt-7" v-show="isEditing == 0">
          <!--          Add Svg Icon-->
          <svg
            @click="addField(input)"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            class="ml-2 cursor-pointer"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill="#6cc24a"
              d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
            />
          </svg>

          <!--          Remove Svg Icon-->
          <svg
            @click="removeField(index)"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            class="ml-2 cursor-pointer"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              fill="#ed9b33"
              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
            />
          </svg>
        </v-col>

        <v-col cols="3" class="text-right">
          <v-btn
            v-if="input.id"
            :disabled="isEditing != 1"
            dense
            color="primary"
            @click="concreteTask(index)"
          >
            {{ $t("meeting_minutes_form.continue_task") }}
          </v-btn>
        </v-col>

        <v-col cols="12" class="pt-8">
          <v-divider></v-divider>
        </v-col>
      </v-row>

      <div class="pt-8 pb-4">
        <p class="headline text--secondary">
          {{ $t("meeting_minutes_form.attachment") }}
        </p>
      </div>

      <v-row>
        <v-col cols="12">
          <!-- :accept="'image/*,video/*,.pdf,.zip,.doc,.docx,.xml,'" -->
          <VueFileAgent
            @upload="filesUploaded()"
            :uploadUrl="urlVueFileAgent"
            :multiple="true"
            :deletable="true"
            :linkable="true"
            :read="true"
            :meta="true"
            :maxSize="'500MB'"
            @select="filesSelected()"
            :maxFiles="30"
            :helpText="'Vyberte súbory'"
            :errorText="{
              type: 'Povolené sú len koncovky: .png, .jpg, .jpeg, .pdf, .zip, .doc, .docx, .xml, .mp4',
              size: 'Prosim vyberte súbor veľkosti maximálne 25mb',
            }"
            :thumbnailSize="120"
            :theme="'list'"
            v-model="fileRecords"
          ></VueFileAgent>
        </v-col>
      </v-row>

      <v-alert v-show="errorAxios" class="mb-6" type="error">
        {{ $t("meeting_table.error_connection") }}
      </v-alert>

      <v-row class="pb-16 pt-6">
        <v-spacer></v-spacer>
        <v-col
          v-if="isEditing == 0 && loadExistMeetings == 0"
          class="text-center"
          cols="12"
          lg="3"
        >
          <v-btn color="primary" @click="saveOnline()">
            {{ $t("meeting_minutes_form.submit") }}
          </v-btn>
        </v-col>

        <v-col
          class="text-center"
          cols="12"
          lg="3"
          v-if="isEditing == 0 && loadExistMeetings == 1 && editable()"
        >
          <v-btn color="primary" @click="saveContinualOnline()">
            {{ $t("meeting_minutes_form.save_button") }}
          </v-btn>
        </v-col>

        <!-- <v-col
          class="text-center"
          cols="12"
          lg="3"
          v-if="isEditing == 0 && loadExistMeetings == 0"
        >
          <v-btn color="primary" @click="saveOffline()">
            {{ $t("meeting_minutes_form.offline") }}
          </v-btn>
        </v-col> -->
        <v-col class="text-center" cols="12" lg="3">
          <v-btn color="primary" @click="saveWord()">
            {{ $t("meeting_minutes_form.word") }}
          </v-btn>
        </v-col>

        <v-col
          class="text-center"
          cols="12"
          lg="3"
          v-if="isEditing == 1 && editable()"
        >
          <v-btn class="ml-10" color="primary" @click="goEditing()">
            <v-icon dark class="mr-2"> mdi-tooltip-edit </v-icon>
            {{ $t("meeting_minutes_form.editing") }}
          </v-btn>
        </v-col>

        <v-col class="text-center" cols="12" lg="3">
          <v-btn color="primary" @click="prepareEmail()">
            {{ $t("meeting_minutes_form.prepare_email") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>


<script>
import axios from "axios";
import docxtemplater from "docxtemplater";
import jszip from "jszip";

import JSZipUtils from "jszip-utils";
import { saveAs } from "file-saver";
import { mapGetters } from "vuex";

import Vue from "vue";
import VueFileAgent from "vue-file-agent";
import VueFileAgentStyles from "vue-file-agent/dist/vue-file-agent.css";
import { VueOfflineStorage } from "vue-offline";

Vue.use(VueFileAgent);
Vue.use(VueFileAgentStyles);

export default {
  data: () => ({
    id_token: "",
    chosenFile: null,
    idm: "",
    admin: false,
    canEdit: false,
    urlVueFileAgent: "",
    toDoLists: [
      {
        name: "",
        responsible: "",
        deadline: null,
        menu: false,
        actual: "",
        addUser: false,
        showTaskType: false,
        showDate: false,
        showStatus: false,
        showResponsible: false,
      },
    ],
    customToolbar: [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
    ],

    fileRecords: [],

    isEditing: false,
    loading: true,
    actualLoading: 0,
    errorAxios: false,
    meetingID: null,
    loadExistMeetings: false,
    editingID: "",
    actualRow: "",
    textPlace: "",
    textPosition: "",
    textPhone: "",
    textOrganization: "",
    actualIndex: 0,

    textName: "",
    textEmail: "",
    textTopic: "",
    editorAgenda: "",
    personType: "",
    itemOvm: [],
    itemOvmObj: [],
    itemProject: [],
    itemTaskType: [],
    itemPersons: [],
    itemVersion: [],
    itemStatusObj: [],
    itemStatus: [],
    itemVersionObj: [],
    itemProjectObj: [],
    itemTaskTypeObj: [],
    selectNumber: [],
    itemNumberActual: [],
    itemNumber: [],
    selectVersion: "",
    initVersion: "",
    selectOvm: "",
    selectTaskType: "",
    selectStatus: "",
    selectOvmAddUser: "",
    selectApprovedPerson: "",
    selectProject: "",
    selectProcessedPerson: "",
    selectPerson: "",

    actualApprovedPerson: "",
    actualProcessedPerson: "",
    actualPerson: "",

    valid: false,
    requiredField: [(v) => !!v || "Toto pole je potrebné vyplniť"],

    date: null,
    menuDate: false,
    menuTimeBegin: false,
    menuTimeEnd: false,
    menuDateToDo: false,
    timeBegin: null,
    timeEnd: null,

    dialogNewPerson: false,

    dialogDeletRow: false,
    dialogError: false,
    dialogSave: false,
    dialogSaveWord: false,
    headersParticipants: [
      { text: "Meno a priezvisko", value: "name" },
      { text: "Pozícia", value: "position" },
      { text: "Organizácia", value: "ovm.name" },
    ],
    participants: [],
    participantsList: [{ actual: "", select: "", addUser: false }],
    persons: [],
  }),
  mounted() {
    this.actualLoading = 0;
    this.loadData();
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getToken", "getIsAdmin"]),
  },
  methods: {
    setLoadingBlock() {
      this.actualLoading = this.actualLoading + 1;
      this.loading = true;
    },
    completeLoadingBlock() {
      this.actualLoading = this.actualLoading - 1;
      if (this.actualLoading == 0) {
        this.loading = false;
      }
    },
    async loadData() {
      this.id_token = this.getToken;
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();
      this.setLoadingBlock();

      await this.loadEditingApprovalMeeting();
      await Promise.all([
        this.loadPersons(),
        this.loadVersion(),
        this.loadOvm(),
        this.loadProject(),
        this.loadTaskType(),
        this.loadStatus(),
      ])
        .then((values) => {
          this.controlEditing();
        })
        .then((values2) => {
          if (this.$route.params.id && this.$route.query.generateWord) {
            this.setLoadingBlock();
            setTimeout(() => {
              this.saveWord();
              this.completeLoadingBlock();
            }, 1500);
          }
        });
      // await this.loadPersons();
      // await this.loadVersion();
      // await this.loadOvm();
      // await this.loadProject();
      // await this.loadTaskType();
      // await this.loadStatus();
      // await this.controlEditing();
    },
    saveOfflineMode(key, value) {
      if (window.matchMedia("(display-mode: standalone)").matches) {
        VueOfflineStorage.set(key, value);
      }
    },
    loadEditingApprovalMeeting() {
      if (this.$route.params.id) {
        return axios
          .get(
            process.env.VUE_APP_URL +
              process.env.VUE_APP_URL_GET_EDITING_APPROVAL_MEETING +
              "/" +
              this.$route.params.id,
            { headers: { Authorization: `Bearer ${this.id_token}` } }
          )
          .then((response) => {
            this.admin = response.data.admin;
            this.canEdit = response.data.canEdit;
            this.errorAxios = false;
            this.completeLoadingBlock();
          })
          .catch((e) => {
            this.axiosCatchPart(e);
          });
      } else {
        this.completeLoadingBlock();
        return null;
      }
    },

    editable() {
      // console.log(this.isEditing, " ", this.loadExistMeetings, " ", this.getIsAdmin)
      if (this.getIsAdmin == "true") return true;
      return (
        this.canEdit &&
        this.canEdit == true &&
        this.initVersion != process.env.VUE_APP_NONE_EDITABLE_VERSION
      );
    },

    goEditing() {
      this.isEditing = false;
      this.loadExistMeetings = true;
    },

    requiredFieldComboboxOvm(value) {
      if (value) {
        if (!this.isExistOvm(value)) {
          return "Tento OVM nie je v systéme, prosim vyberte zo zoznamu dostupných OVM";
        } else return true;
      } else return "Toto pole je potrebné vyplniť";
    },

    requiredFieldComboboxTaskType(value) {
      if (value) {
        if (!this.isExistTaskType(value)) {
          return "Tento typ nie je v systéme, prosim vyberte zo zoznamu dostupných typov";
        } else return true;
      } else return "Toto pole je potrebné vyplniť";
    },

    requiredFieldComboboxStatus(value) {
      if (value) {
        if (!this.isExistStatus(value)) {
          return "Tento stav nie je v systéme, prosim vyberte zo zoznamu dostupných stavov";
        } else return true;
      } else return "Toto pole je potrebné vyplniť";
    },

    requiredFieldComboboxProject(value) {
      if (value) {
        if (!this.isExistProject(value)) {
          return "Tento projekt nie je v systéme, prosim vyberte zo zoznamu dostupných projektov";
        } else return true;
      } else return "Toto pole je potrebné vyplniť";
    },

    requiredFieldComboboxVersion(value) {
      if (value) {
        if (!this.isExistVersion(value)) {
          return "Táto verzia nie je v systéme, prosim vyberte zo zoznamu dostupných verzií";
        } else return true;
      } else return "Toto pole je potrebné vyplniť";
    },

    requiredFieldUserParticipant(index) {
      if (this.participantsList[index].actual) {
        if (!this.isExistPerson(this.participantsList[index].actual)) {
          this.participantsList[index].addUser = true;
          return "Tento používateľ nie je v systéme, prosim vytvorte ho";
        } else {
          this.participantsList[index].addUser = false;
          return true;
        }
      } else {
        this.participantsList[index].addUser = false;

        return "Toto pole je potrebné vyplniť";
      }
    },

    requiredFieldUserTask(index) {
      if (this.toDoLists[index].actual) {
        if (!this.isExistPerson(this.toDoLists[index].actual)) {
          this.toDoLists[index].addUser = true;
          return "Tento používateľ nie je v systéme, prosim vytvorte ho";
        } else {
          this.toDoLists[index].addUser = false;
          return true;
        }
      } else {
        this.toDoLists[index].addUser = false;
        return "Toto pole je potrebné vyplniť";
      }
    },

    requiredFieldUser(value) {
      if (value) {
        if (!this.isExistPerson(value)) {
          return "Tento používateľ nie je v systéme, prosim vytvorte ho";
        } else return true;
      } else return "Toto pole je potrebné vyplniť";
    },
    requiredFieldUserProcessed() {
      let value = this.actualProcessedPerson;

      if (value) {
        if (!this.isExistPerson(value)) {
          return "Tento používateľ nie je v systéme, prosim vytvorte ho";
        } else return true;
      } else return "Toto pole je potrebné vyplniť";
    },

    requiredFieldUserApproved() {
      let value = this.actualApprovedPerson;

      if (value) {
        if (!this.isExistPerson(value)) {
          return "Tento používateľ nie je v systéme, prosim vytvorte ho";
        } else return true;
      } else return "Toto pole je potrebné vyplniť";
    },
    filesSelected() {
      this.setLoadingBlock();
    },
    filesUploaded() {
      let recordsHelp = this.fileRecords;
      recordsHelp = this.fileRecords.map(function (record) {
        if (record.upload.data) {
          record = {
            url:
              process.env.VUE_APP_URL +
              process.env.VUE_APP_URL_DOWNLOAD_ATTACHMENT +
              "?file=" +
              record.upload.data.key,
            originalName: record.upload.data.name,
            name: record.upload.data.name,
            size: record.upload.data.size,
            key: record.upload.data.key,
            type: "app",
          };
        }
        return record;
      });
      this.fileRecords = recordsHelp;
      this.completeLoadingBlock();
    },

    controlEditing() {
      // console.log("doslo s");
      this.urlVueFileAgent =
        process.env.VUE_APP_URL + process.env.VUE_APP_URL_UPLOAD_ATTACHMENT;
      this.itemNumber = Array.from({ length: 100 }, (_, i) => i + 1);
      this.itemNumberActual = Array.from({ length: 100 }, (_, i) => i + 1);
      if (this.$route.params.id) {
        this.loadExistMeetings = true;
        this.editingID = this.$route.params.id;

        if (this.$router.currentRoute.name === "MeetingShow") {
          this.isEditing = true;
        }
        if (this.$router.currentRoute.name === "MeetingCopy") {
          this.isEditing = false;
          this.loadExistMeetings = false;
        }

        this.setLoadingBlock();
        axios
          .get(
            process.env.VUE_APP_URL +
              process.env.VUE_APP_URL_GET_MEETING +
              "/" +
              this.$route.params.id,
            { headers: { Authorization: `Bearer ${this.id_token}` } }
          )
          .then((response) => {
            let res = response.data;
            this.selectOvm = res.ovm.name;
            this.selectProject = res.project.name;
            this.selectProcessedPerson =
              res.processedBy.name +
              ", " +
              res.processedBy.position +
              ", " +
              res.processedBy.ovm.name;
            this.selectApprovedPerson =
              res.approvedBy.name +
              ", " +
              res.approvedBy.position +
              ", " +
              res.approvedBy.ovm.name;

            this.actualProcessedPerson =
              res.processedBy.name +
              ", " +
              res.processedBy.position +
              ", " +
              res.processedBy.ovm.name;
            this.actualApprovedPerson =
              res.approvedBy.name +
              ", " +
              res.approvedBy.position +
              ", " +
              res.approvedBy.ovm.name;

            this.participants = res.persons;

            this.participantsList = this.participants.map(function (obj) {
              let help = obj.name + ", " + obj.position + ", " + obj.ovm.name;
              return { actual: help, select: help, addUser: false };
            });
            if (this.$router.currentRoute.name !== "MeetingCopy") {
              (this.idm = response.data.project.acronym + response.data.id),
                (this.selectVersion = res.version.name);

              this.fileRecords = res.attachments;

              this.fileRecords.forEach((record) => (record.type = "app"));

              this.fileRecords.forEach(
                (record) => (record.originalName = record.name)
              );

              this.fileRecords.forEach(
                (record) =>
                  (record.url =
                    process.env.VUE_APP_URL +
                    process.env.VUE_APP_URL_DOWNLOAD_ATTACHMENT +
                    "?file=" +
                    record.key)
              );

              this.initVersion = res.version.name;
              this.textTopic = res.topic;
              this.date = res.date.substring(0, 10);
              this.timeBegin = res.timeBegin.substring(11, 16);
              this.timeEnd = res.timeEnd.substring(11, 16);
              this.editorAgenda = res.agenda;
              this.textPlace = res.place;
              let helpTask = res.tasks;

              let personsHelp = this.persons;
              this.toDoLists = helpTask.map(function (obj) {
                var element = personsHelp.filter((p) => p.id === obj.personId);
                let help = "";
                if (element[0])
                  help =
                    element[0].name +
                    ", " +
                    element[0].position +
                    ", " +
                    element[0].ovm.name;
                let status = "";
                if (obj.status) {
                  status = obj.status.name;
                }
                let helpDeadline = "";
                if (obj.deadline) helpDeadline = obj.deadline.substring(0, 10);

                return {
                  id: obj.id,
                  name: obj.name,
                  responsible: help,
                  deadline: helpDeadline,
                  menu: false,
                  actual: help,
                  addUser: false,
                  showTaskType: false,
                  showDate: false,
                  showStatus: false,
                  showResponsible: false,
                  selectStatus: status,
                  selectTaskType: obj.taskType.name,
                };
              });
            }
            this.completeLoadingBlock();
          })
          .catch((e) => {
            this.axiosCatchPart(e);
          });
      }
    },
    loadVersion() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_VERSION, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemVersionObj = response.data;

          this.itemVersion = [
            ...new Set(this.itemVersionObj.map((item) => item.name)),
          ];

          this.saveOfflineMode("versions", response.data);

          this.itemVersion.sort();
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadOvm() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_OVM, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemOvmObj = response.data;

          this.itemOvm = [...new Set(this.itemOvmObj.map((item) => item.name))];
          this.saveOfflineMode("ovm", response.data);
          this.completeLoadingBlock();
          this.itemOvm.sort();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadTaskType() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_TASKTYPE, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemTaskTypeObj = response.data;

          this.itemTaskType = [
            ...new Set(this.itemTaskTypeObj.map((item) => item.name)),
          ];
          this.saveOfflineMode("taskTypes", response.data);
          this.completeLoadingBlock();
          this.itemTaskType.sort();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadProject() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_PROJECT, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemProjectObj = response.data;

          this.itemProject = [
            ...new Set(this.itemProjectObj.map((item) => item.name)),
          ];
          this.saveOfflineMode("projects", response.data);
          this.completeLoadingBlock();
          this.itemProject.sort();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadStatus() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_STATUS, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.itemStatusObj = response.data;

          this.itemStatus = [
            ...new Set(this.itemStatusObj.map((item) => item.name)),
          ];
          this.saveOfflineMode("status", response.data);
          this.completeLoadingBlock();
          this.itemStatus.sort();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    loadPersons() {
      return axios
        .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_PERSON, {
          headers: { Authorization: `Bearer ${this.id_token}` },
        })
        .then((response) => {
          this.persons = response.data;
          this.itemPersons = this.persons
            .filter((p) => {
              return p.exist == 1;
            })
            .map(function (p) {
              return p.name + ", " + p.position + ", " + p.ovm.name;
            });
          this.itemPersons.sort();
          this.saveOfflineMode("persons", response.data);
          this.completeLoadingBlock();
        })
        .catch((e) => {
          this.axiosCatchPart(e);
        });
    },
    onChangeTaskType(index) {
      this.toDoLists[index].uid = index + 1;
      if (this.toDoLists[index].selectTaskType == "U – úloha") {
        this.toDoLists[index].showName = true;
        this.toDoLists[index].showDate = true;
        this.toDoLists[index].showStatus = true;
        this.toDoLists[index].showResponsible = true;
      }
      if (this.toDoLists[index].selectTaskType == "R – riziko") {
        this.toDoLists[index].showName = true;
        this.toDoLists[index].showDate = false;
        this.toDoLists[index].showStatus = false;
        this.toDoLists[index].showResponsible = false;
      }
      if (this.toDoLists[index].selectTaskType == "O – otvorená") {
        this.toDoLists[index].showName = true;
        this.toDoLists[index].showDate = true;
        this.toDoLists[index].showStatus = true;
        this.toDoLists[index].showResponsible = true;
      }

      if (this.toDoLists[index].selectTaskType == "D – dohoda") {
        this.toDoLists[index].showName = true;
        this.toDoLists[index].showDate = false;
        this.toDoLists[index].showStatus = false;
        this.toDoLists[index].showResponsible = false;
      }
      if (this.toDoLists[index].selectTaskType == "I – informácia") {
        this.toDoLists[index].showName = true;
        this.toDoLists[index].showDate = false;
        this.toDoLists[index].showStatus = false;
        this.toDoLists[index].showResponsible = false;
      }
      if (this.toDoLists[index].selectTaskType == "P – predpoklad") {
        this.toDoLists[index].showName = true;
        this.toDoLists[index].showDate = false;
        this.toDoLists[index].showStatus = false;
        this.toDoLists[index].showResponsible = false;
      }

      if (this.toDoLists[index].selectTaskType == "V – výstup") {
        this.toDoLists[index].showName = true;
        this.toDoLists[index].showDate = false;
        this.toDoLists[index].showStatus = false;
        this.toDoLists[index].showResponsible = false;
      }
    },
    isExistPerson(name) {
      if (this.itemPersons.indexOf(name) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    isExistPersonParticipant(index) {
      if (!this.participantsList[index].actual) return false;
      if (
        this.itemPersons.indexOf(this.participantsList[index].actual) !== -1
      ) {
        return true;
      } else {
        return false;
      }
    },
    isExistOvm(name) {
      if (this.itemOvm.indexOf(name) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    isExistProject(name) {
      if (this.itemProject.indexOf(name) !== -1) {
        return true;
      } else {
        return false;
      }
    },

    isExistVersion(name) {
      if (this.itemVersion.indexOf(name) !== -1) {
        return true;
      } else {
        return false;
      }
    },

    isExistTaskType(name) {
      if (this.itemTaskType.indexOf(name) !== -1) {
        return true;
      } else {
        return false;
      }
    },

    isExistStatus(name) {
      if (this.itemStatus.indexOf(name) !== -1) {
        return true;
      } else {
        return false;
      }
    },

    axiosCatchPart(e) {
      if (e.name != "TypeError") {
        this.errorAxios = true;
      }

      if (e.response.status == 403) {
        if (
          this.$router.currentRoute.name === "MeetingShow" ||
          this.$router.currentRoute.name === "MeetingCopy" ||
          this.$router.currentRoute.name === "MeetingUpdate"
        )
          this.$router.go(-1).catch(() => {});
        else this.$router.push("login").catch(() => {});
      }
      this.completeLoadingBlock();
    },

    onChangeParticipantPerson(index) {
      this.textName = this.participantsList[index].actual;
      if (!this.isExistPerson(this.participantsList[index].actual)) {
        this.personType = "person";
        this.actualIndex = index;
        this.dialogNewPerson = true;
      } else {
        this.personType = "";
      }
    },

    onChangeTaskPerson(index) {
      this.textName = this.toDoLists[index].actual;
      if (!this.isExistPerson(this.toDoLists[index].actual)) {
        this.personType = "toDoPerson";
        this.actualIndex = index;
        this.dialogNewPerson = true;
      } else {
        this.personType = "";
      }
    },

    onChangeApprovedPerson() {
      this.textName = this.actualApprovedPerson;
      if (!this.isExistPerson(this.actualApprovedPerson)) {
        this.personType = "approvedPerson";
        this.dialogNewPerson = true;
      } else {
        this.personType = "";
      }
    },
    onChangeProcessedPerson() {
      this.textName = this.actualProcessedPerson;
      if (!this.isExistPerson(this.actualProcessedPerson)) {
        this.personType = "processedPerson";
        this.dialogNewPerson = true;
      } else {
        this.personType = "";
      }
    },
    onChangePersonToDo(index) {
      this.textName = this.toDoLists[index].responsible;

      if (!this.isExistPerson(this.toDoLists[index].responsible)) {
        this.personType = "toDoPerson";
        this.actualIndex = index;
        this.dialogNewPerson = true;
      } else {
        this.personType = "";
      }
    },
    onChangePerson() {
      this.textName = this.selectPerson;

      if (!this.isExistPerson(this.selectPerson)) {
        this.personType = "person";
        this.dialogNewPerson = true;
      } else {
        this.personType = "";
      }
    },
    closeAddPerson() {
      this.textPhone = "";
      this.textPosition = "";
      this.selectOvmAddUser = "";
      this.textEmail = "";
      this.dialogNewPerson = false;
    },
    addField() {
      this.toDoLists.push({
        name: "",
        responsible: "",
        actual: "",
        addUser: false,
        deadline: null,
        menu: false,
        showTaskType: false,
        showDate: false,
        showStatus: false,
        showResponsible: false,
      });
    },
    addFirstField() {
      this.toDoLists = [
        {
          name: "",
          responsible: "",
          actual: "",
          addUser: false,
          deadline: null,
          menu: false,
          showTaskType: false,
          showDate: false,
          showStatus: false,
          showResponsible: false,
        },
      ];
    },
    removeField(index) {
      this.toDoLists.splice(index, 1);
    },
    concreteTask(index) {
      this.$router.push({
        name: "TaskShow",
        params: { id: this.toDoLists[index].id },
      });
    },
    addFieldParticipantList() {
      this.participantsList.push({ actual: "", select: "", addUser: false });
    },
    addFirstFieldParticipantList() {
      this.participantsList = [{ actual: "", select: "", addUser: false }];
    },
    removeFieldParticipantList(index) {
      this.participantsList.splice(index, 1);
    },

    deleteTableRow() {
      this.dialogDeletRow = false;
      this.participants.splice(this.actualRow, 1);
    },
    clickTableRow(index) {
      if (!this.isEditing) {
        this.actualRow = index;
        this.dialogDeletRow = true;
      }
    },
    addPersonInTable() {
      if (this.selectPerson == "") {
        this.textName = "";
        this.closeAddPerson;
      } else {
        this.dialogNewPerson = false;
        var element = this.persons.filter(
          (p) =>
            p.name + ", " + p.position + ", " + p.ovm.name === this.selectPerson
        );

        const found = this.participants.some((p) => {
          if (p == element[0]) {
            return true;
          } else {
            return false;
          }
        });
        if (!found) {
          this.participants.push(element[0]);
        }

        this.textName = "";
        this.selectPerson = "";
        this.personType = "";
      }
    },
    getActualJson() {
      let personsHelp = this.persons;
      let processed_by = this.persons.filter(
        (p) =>
          p.name + ", " + p.position + ", " + p.ovm.name ===
          this.selectProcessedPerson
      );
      let approved_by = this.persons.filter(
        (p) =>
          p.name + ", " + p.position + ", " + p.ovm.name ===
          this.selectApprovedPerson
      );

      let ovmItem = this.itemOvmObj.filter((p) => p.name === this.selectOvm);

      let participantsListClear = this.participantsList.slice();
      participantsListClear.filter((o) => o.actual);

      let actualParticipant = [];
      participantsListClear.map(function (p) {
        let participant = personsHelp.filter(
          (tp) => tp.name + ", " + tp.position + ", " + tp.ovm.name === p.actual
        )[0];

        actualParticipant.push(participant);
        return p;
      });

      let toDoListClear = this.toDoLists.map((object) => ({ ...object })); //this.toDoLists.slice();
      toDoListClear.filter((o) => o.name);
      let versionitem = this.itemVersionObj.filter(
        (p) => p.name === this.selectVersion
      );

      let actualProject = this.itemProjectObj.filter(
        (p) => this.selectProject === p.name
      );
      let taskTypeHelp = this.itemTaskTypeObj;
      let taskStatusHelp = this.itemStatusObj;
      let editedToDoListClear = toDoListClear.map(function (p) {
        let taskPerson = personsHelp.filter(
          (tp) =>
            tp.name + ", " + tp.position + ", " + tp.ovm.name === p.responsible
        );

        let concretePersonName = null;
        let concretePersonID = null;
        if (taskPerson[0]) {
          concretePersonName = taskPerson[0].name;
          concretePersonID = taskPerson[0].id;
        }
        p.taskType = null;

        let taskTypeCreate = taskTypeHelp.filter(
          (tp) => p.selectTaskType === tp.name
        )[0];

        if (taskTypeCreate) {
          p.taskType = taskTypeCreate;
        }
        p.status = null;
        let statusCreate = taskStatusHelp.filter(
          (tp) => p.selectStatus === tp.name
        )[0];
        if (statusCreate) {
          p.status = statusCreate;
        }
        p.status = statusCreate;
        p.personId = concretePersonID;
        p.responsible = concretePersonName;
        p.comment = null;
        delete p.menu;
        return p;
      });

      let attachments = [];
      this.fileRecords.map(function (a) {
        attachments.push({
          id: a.id,
          meetingId: a.meetingId,
          name: a.originalName,
          size: a.size,
          key: a.key,
        });
        // }
      });

      let json = {
        attachments: attachments,
        project: actualProject[0],
        ovm: ovmItem[0],
        processedBy: processed_by[0],
        approvedBy: approved_by[0],
        persons: actualParticipant,
        tasks: editedToDoListClear,
        version: versionitem[0],
        topic: this.textTopic,
        // attachment: actualAttachment,
        date: this.date.substring(0, 10) + "T00:00:00.000+00:00",
        timeBegin:
          this.date.substring(0, 10) +
          "T" +
          this.timeBegin.substring(0, 6) +
          ":00.000+00:00",
        timeEnd:
          this.date.substring(0, 10) +
          "T" +
          this.timeEnd.substring(0, 6) +
          ":00.000+00:00",

        agenda: this.editorAgenda,
        place: this.textPlace,
      };

      return json;
    },
    saveWord() {
      let helpIdm = this.idm;
      if (this.$refs.form.validate()) {
        this.$refs.form.validate();

        let offlineJson = this.getActualJson();

        offlineJson.date = offlineJson.date.substring(0, 10);
        offlineJson.timeBegin = offlineJson.timeBegin.substring(11, 16);
        offlineJson.timeEnd = offlineJson.timeEnd.substring(11, 16);

        offlineJson.tasks.forEach(function (task) {
          if (task.deadline) {
            task.deadline = task.deadline.substring(0, 10);
          }
        });

        let offlineTopic = this.textTopic;
        let offlineVersion = this.selectVersion;
        JSZipUtils.getBinaryContent(
          "/ReportTemplates/meeting_template.docx",
          function (error, content) {
            if (error) {
              throw error;
            }
            let zip = new jszip(content);
            let doc = new docxtemplater(zip, {
              nullGetter() {
                return "-";
              },
              linebreaks: true,
            });
            doc.setData(offlineJson);
            try {
              doc.render();
            } catch (error) {
              let e = {
                message: error.message,
                name: error.name,
                stack: error.stack,
                properties: error.properties,
              };
              throw error;
            }
            // docx generating
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, "0");
            var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
            var yyyy = today.getFullYear();
            let acronymProject = "";
            if (helpIdm) acronymProject = "_" + helpIdm;
            let out = doc.getZip().generate({
              type: "blob",
              mimeType:
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            });

            let namefile =
              "Zápis" +
              acronymProject +
              "_" +
              offlineTopic +
              "_" +
              yyyy +
              mm +
              dd +
              "_" +
              offlineVersion +
              ".docx";

            namefile = namefile.replaceAll(" ", "_");
            saveAs(out, namefile); // You can pass this blob to a custom file saver component in the project.
          }
        );
        this.dialogSaveWord = true;
      } else {
        this.dialogError = true;
      }
    },

    saveContinualOnline() {
      if (this.$refs.form.validate()) {
        let body = this.getActualJson();
        let concreteID = "";
        if (this.$route.params.id) {
          concreteID = "/" + this.$route.params.id;
        }
        if (this.meetingID) {
          concreteID = "/" + this.meetingID;
        }

        var optionAxios = {
          headers: {
            Authorization: `Bearer ${this.id_token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json; charset=utf8",
          },
        };
        this.setLoadingBlock();
        axios
          .patch(
            process.env.VUE_APP_URL +
              process.env.VUE_APP_URL_GET_MEETING +
              concreteID,
            body,
            optionAxios
          )
          .then((response) => {
            this.meetingID = response.data.id;
            let responseTasks = response.data.tasks;
            this.toDoLists.forEach(function (task) {
              let idTask = responseTasks.find(
                (element) => element.name == task.name
              );
              task.id = idTask.id;
            });
            this.idm = response.data.project.acronym + response.data.id;
            this.completeLoadingBlock();
            this.dialogSave = true;
          })
          .catch((e) => {
            this.axiosCatchPart(e);
          });
      } else {
        this.dialogError = true;
      }
    },
    saveOffline() {
      if (this.$refs.form.validate()) {
        let offlineJson = this.getActualJson();
        var file = new Blob([JSON.stringify(offlineJson)], {
          type: "text/plain;charset=utf-8",
        });
        let namefile = "Zápis_DI_" + this.textTopic + "_ver.txt";
        saveAs(file, namefile);
        this.dialogSave = true;
      } else {
        this.dialogError = true;
      }
    },
    saveOnline() {
      if (this.$refs.form.validate()) {
        let body = this.getActualJson();
        var optionAxios = {
          headers: {
            Authorization: `Bearer ${this.id_token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json; charset=utf8",
          },
        };
        this.setLoadingBlock();
        axios
          .post(
            process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_MEETING,
            body,
            optionAxios
          )
          .then((response) => {
            this.meetingID = response.data.id;
            let responseTasks = response.data.tasks;
            this.$router.go(-1);
            this.loadExistMeetings = 1;
            this.canEdit == true;
            this.toDoLists.forEach(function (task) {
              let idTask = responseTasks.find(
                (element) => element.name == task.name
              );
              if (idTask && idTask[0] && idTask[0].id) {
                task.id = idTask[0].id;
              } else task.id = "";
            });
            this.idm = response.data.project.acronym + response.data.id;

            this.completeLoadingBlock();
            this.dialogSave = true;
          })
          .catch((e) => {
            this.axiosCatchPart(e);
          });
      } else {
        this.dialogError = true;
      }
    },
    findOvmByName(name) {
      return this.itemOvmObj.filter((p) => p.name === name);
    },
    prepareEmail() {
      if (this.$refs.form.validate()) {
        let processed_by = this.persons.filter(
          (p) =>
            p.name + ", " + p.position + ", " + p.ovm.name ===
            this.selectProcessedPerson
        );
        let approved_by = this.persons.filter(
          (p) =>
            p.name + ", " + p.position + ", " + p.ovm.name ===
            this.selectApprovedPerson
        );
        let pe = "";
        let ae = "";
        if (processed_by.email) pe = ";" + processed_by.email;
        if (approved_by.email) ae = ";" + approved_by.email;
        let subject =
          "Zápisnica zo stretnutia " + this.textTopic + " dňa " + this.date;
        let participantsEmail =
          this.participants
            .filter((element) => element.email !== undefined)
            .map((p) => p.email)
            .join(";") +
          pe +
          ae;
        console.log(participantsEmail);
        let body =
          "Dobrý deň,%0A zasielam Vám zápisnicu zo stretnutia na priloženú v prílohe.";

        window.location.href =
          "mailto:" +
          participantsEmail +
          "?subject=" +
          subject +
          "&body=" +
          body;
      } else {
        this.dialogError = true;
        return true;
      }
    },

    loadOfflineData() {
      var reader = new FileReader();

      reader.readAsText(this.chosenFile);
      reader.onload = () => {
        // console.log(offlineData);
        let offlineData = JSON.parse(reader.result);
        if (offlineData.project) this.selectProject = offlineData.project;
        else this.selectProject = "";
        if (offlineData.ovm) this.selectOvm = offlineData.ovm;
        else this.selectOvm = "";
        if (offlineData.processedBy) {
          this.selectProcessedPerson = offlineData.processedBy;
          this.actualProcessedPerson = offlineData.processedBy;
        } else this.selectProcessedPerson = "";
        if (offlineData.approvedBy) {
          this.selectApprovedPerson = offlineData.approvedBy;
          this.actualApprovedPerson = offlineData.approvedBy;
        } else this.selectApprovedPerson = "";
        if (offlineData.persons) this.participantsList = offlineData.persons;
        else this.participantsList = "";
        this.participantsList = this.participantsList.map((obj) => {
          obj.actual = obj.select;
          return obj;
        });
        if (offlineData.tasks) this.toDoLists = offlineData.tasks;
        else this.toDoLists = "";
        this.toDoLists = this.toDoLists.map((obj) => {
          obj.actual = obj.responsible;
          return obj;
        });
        if (offlineData.version) this.selectVersion = offlineData.version;
        else this.selectVersion = "";
        if (offlineData.topic) this.textTopic = offlineData.topic;
        else this.textTopic = "";
        if (offlineData.date) this.date = offlineData.date.substring(0, 10);
        else this.date = "";
        if (offlineData.timeBegin)
          this.timeBegin = offlineData.timeBegin.substring(11, 16);
        else this.timeBegin = "";
        if (offlineData.timeEnd)
          this.timeEnd = offlineData.timeEnd.substring(11, 16);
        else this.timeEnd = "";
        if (offlineData.agenda) this.editorAgenda = offlineData.agenda;
        else this.editorAgenda = "";
        if (offlineData.place) this.textPlace = offlineData.place;
        else this.textPlace = "";
      };
    },
    addPerson() {
      let newPerson = "";
      if (this.selectOvmAddUser == "" || this.textName == "") {
        this.dialogError = true;
      } else {
        if (this.textEmail == "") this.textEmail = "-";
        if (this.textPosition == "") this.textPosition = "-";
        if (this.textPhone == "") this.textPhone = "-";
        this.setLoadingBlock();
        let ovm = this.findOvmByName(this.selectOvmAddUser);
        let body = {
          ovm: { id: ovm[0].id, name: ovm[0].name, exist: ovm[0].exist },
          name: this.textName,
          email: this.textEmail,
          position: this.textPosition,
          phone: this.textPhone,
        };

        var optionAxios = {
          headers: {
            Authorization: `Bearer ${this.id_token}`,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json; charset=utf8",
          },
        };
        axios
          .post(
            process.env.VUE_APP_URL + process.env.VUE_APP_URL_GET_PERSON,
            body,
            optionAxios
          )
          .then((response) => {
            newPerson = response.data;
            this.setLoadingBlock();
            this.loadPersons().then((x) => {
              let personTextFieldName =
                newPerson.name +
                ", " +
                newPerson.position +
                ", " +
                newPerson.ovm.name;
              if (this.personType == "person") {
                this.participantsList[this.actualIndex].actual =
                  personTextFieldName;
                this.participantsList[this.actualIndex].select =
                  personTextFieldName;
                this.selectPerson = personTextFieldName;
              }

              if (this.personType == "processedPerson") {
                this.selectProcessedPerson = personTextFieldName;
                this.actualProcessedPerson = personTextFieldName;
              }
              if (this.personType == "toDoPerson") {
                this.toDoLists[this.actualIndex].responsible =
                  personTextFieldName;
                this.toDoLists[this.actualIndex].actual = personTextFieldName;
              }
              if (this.personType == "approvedPerson") {
                this.selectApprovedPerson = personTextFieldName;
                this.actualApprovedPerson = personTextFieldName;
              }
              this.dialogNewPerson = false;
              this.textName = "";
              this.personType = "";
              this.textPosition = "";
              this.textPhone = "";
              this.selectOvmAddUser = "";
              this.textEmail = "";
              return x;
            });
            this.completeLoadingBlock();
          })
          .catch((e) => {
            this.axiosCatchPart(e);
          });
      }
    },
  },
};
</script>


<style scoped>
#profile-pic-demo .drop-help-text {
  display: none;
}
#profile-pic-demo .is-drag-over .drop-help-text {
  display: block;
}
#profile-pic-demo .profile-pic-upload-block {
  border: 2px dashed transparent;
  padding: 20px;
  padding-top: 0;
}

#profile-pic-demo .is-drag-over.profile-pic-upload-block {
  border-color: #aaa;
}
#profile-pic-demo .vue-file-agent {
  width: 180px;
  float: left;
  margin: 0 15px 5px 0;
  border: 0;
  box-shadow: none;
}
</style>